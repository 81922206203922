import request from "../utils/request";
//获取各类型banner列表
export const getbannerlist = data => {
  return request({
    method: 'POST',
    url: '/system/banner/getCategories',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取banner详情
export const getbannerdetail = data => {
  return request({
    method: 'POST',
    url: '/system/banner/getOriginalBannerDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//新建banner
export const createbanner = data => {
  return request({
    method: 'POST',
    url: '/system/banner/createOriginalBanner',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除banner
export const delbanner = data => {
  return request({
    method: 'POST',
    url: '/system/banner/deleteOriginalBanner',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改banner
export const changebanner = data => {
  return request({
    method: 'POST',
    url: '/system/banner/modifyOriginalBanner',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//上线的限制
export const checkshangxian = data => {
  return request({
    method: 'POST',
    url: '/system/banner/checkOnline',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取核心技术分类列表
export const getTypeList = data => {
  return request({
    method: 'POST',
    url: '/system/skill/getSkillCategories',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}

//新建核心技术类目
export const CreateType = data => {
  return request({
    method: 'post',
    url: '/system/skill/createSkillCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除核心技术类目
export const delType = data => {
  return request({
    method: 'post',
    url: '/system/skill/deleteSkillCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取核心技术类目详情
export const getTypeById = data => {
  return request({
    method: 'post',
    url: '/system/skill/getSkillCategoryDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改核心技术类目
export const ChangeType = data => {
  return request({
    method: 'post',
    url: '/system/skill/modifySkillCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ==========================================================================
//选择核心技术分类
export const chooseType = data => {
  return request({
    method: 'get',
    url: '/system/skill/chooseSkillCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//新建核心技术
export const createContent = data => {
  return request({
    method: 'post',
    url: '/system/skill/createSkill',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除核心技术
export const delContent = data => {
  return request({
    method: 'post',
    url: '/system/skill/deleteSkill',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取核心技术详情
export const getdetailById = data => {
  return request({
    method: 'post',
    url: '/system/skill/getSkillDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取核心技术列表
export const getContentList = data => {
  return request({
    method: 'post',
    url: '/system/skill/getSkills',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改核心技术
export const changeContent = data => {
  return request({
    method: 'post',
    url: '/system/skill/modifySkill',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}