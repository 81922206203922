import request from "../utils/request";

//新建荣誉资质
export const createHonor = data => {
  return request({
    method: 'POST',
    url: '/system/honors/addHonor',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除荣誉资质
export const delHonor = data => {
  return request({
    method: 'post',
    url: '/system/honors/deleteHonor',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取荣誉资质详情
export const getHonorDetail = data => {
  return request({
    method: 'post',
    url: '/system/honors/getDetailHonor',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取荣誉资质列表
export const getHonorList = data => {
  return request({
    method: 'post',
    url: '/system/honors/getHonors',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改荣誉资质
export const changeHonor = data => {
  return request({
    method: 'POST',
    url: '/system/honors/updateHonor',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}